





























































































































import {
  defineComponent, onUpdated, PropType, ref,
} from '@vue/composition-api';
import { TripEventModelWithTask } from '@/models/trip/TripEventModelWithTask';
import dateFormats from '@/helpers/DateFormats';
import { DateComparer } from '@/helpers/DateComparer';
import StationPassageInfo from '@/components/trip/trip-task-card-concrete-info/StationPassageInfo.vue';
import { TripTaskTypeKeys } from '@/models/trip/TripTaskTypeKeys';
import PlaceInfo from '@/components/trip/trip-task-card-concrete-info/PlaceInfo.vue';
import MedicalInfo from '@/components/trip/trip-task-card-concrete-info/MedicalInfo.vue';
import InfrastructureFollowUp
  from '@/components/trip/trip-task-card-concrete-info/InfrastructureFollowUp.vue';

export default defineComponent({
  name: 'TripTaskCard',
  components: {
    InfrastructureFollowUp, MedicalInfo, PlaceInfo, StationPassageInfo,
  },
  props: {
    tripEventWithTask: {
      type: Object as PropType<TripEventModelWithTask>,
      required: true,
    },
  },
  setup: (props, ctx) => {
    const { toTime } = dateFormats();
    const updateCount = ref(0);

    const compareDates = DateComparer.compareToMinutes;
    const { firstDateIsGreater } = DateComparer;

    onUpdated(() => {
      updateCount.value++;
    });

    return {
      updateCount,
      toTime,
      compareDates,
      firstDateIsGreater,
      TripTaskTypeKeys: { ...TripTaskTypeKeys },
    };
  },
});
