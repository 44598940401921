

































































































































import { defineComponent, PropType } from '@vue/composition-api';
import { Trip } from '@/models/trip/Trip';
import dateFormats from '@/helpers/DateFormats';

export default defineComponent({
  name: 'TripSummaryPanel',
  props: {
    trip: {
      type: Object as PropType<Trip>,
      required: true,
    },
  },
  setup: (props) => {
    const { toOnlyTextMonthDate } = dateFormats();

    return {
      toOnlyTextMonthDate,
    };
  },
});
