

















import { defineComponent } from '@vue/composition-api';
import { plainToClass } from 'class-transformer';
import { Place } from '@/models/trip/concrete-trip-task/Place';

export default defineComponent({
  name: 'InfrastructureFollowUp',
  props: {
    followUp: {
      type: Object,
      required: true,
    },
  },
  setup: (props) => {
    const fromPlace = plainToClass(Place, props.followUp.tripTask.from.place);
    const toPlace = plainToClass(Place, props.followUp.tripTask.to.place);

    return { fromPlace, toPlace };
  },
});
