import { PlaceChunk } from '@/models/trip/concrete-trip-task/PlaceChunk';
import { Type } from 'class-transformer';

export class Place {
  @Type(() => PlaceChunk)
  public placeChunks: Array<PlaceChunk> = [];

  public get first() : string {
    return this.placeChunks[0].place.name;
  }

  public get full(): string {
    let result = '';
    const lastChunk = this.placeChunks[this.placeChunks.length - 1];

    this.placeChunks.forEach((chunk) => {
      if (lastChunk === chunk) {
        result += `${chunk.place.name}`;
      } else {
        result += `${chunk.place.name} / `;
      }
    });

    return result.trim();
  }
}
