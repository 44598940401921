import { Type } from 'class-transformer';
import { SimpleModel } from '@/models/base/SimpleModel';
import { EmployeeInCrew } from '@/models/trip/EmployeeInCrew';
import { TripVersion } from '@/models/trip/TripVersion';
import { Summary } from '@/models/trip/Summary';

export class Trip {
  public id = ''

  @Type(() => Date)
  public dateBegin!: Date

  @Type(() => Date)
  public dateEnd: Date | null = null

  @Type(() => EmployeeInCrew)
  public employees : EmployeeInCrew[] = []

  @Type(() => SimpleModel)
  public crewDepot!: SimpleModel

  @Type(() => TripVersion)
  public versions: TripVersion[] = []

  public number = ''

  public isActive = false

  @Type(() => Summary)
  public summary: Summary | null = null

  get lastVersion(): TripVersion {
    const versions = [...this.versions];
    versions.sort((a, b) => a.number - b.number);

    const tripVersion = versions[versions.length - 1];
    return tripVersion;
  }
}
