



























import { defineComponent, PropType } from '@vue/composition-api';
import { StationPassageEventModel } from '@/models/trip/concrete-trip-event-with-task/StationPassageEventModel';
import { Place } from '@/models/trip/concrete-trip-task/Place';
import { plainToClass } from 'class-transformer';

export default defineComponent({
  name: 'StationPassageInfo',
  props: {
    stationPassage: {
      type: Object as PropType<StationPassageEventModel>,
      required: true,
    },
  },
  setup: (props) => {
    const mappedStationPassage = plainToClass(StationPassageEventModel, props.stationPassage);
    return { mappedStationPassage };
  },
});
