

















import { defineComponent, PropType } from '@vue/composition-api';
import { Place } from '@/models/trip/concrete-trip-task/Place';
import { plainToClass } from 'class-transformer';

export default defineComponent({
  name: 'PlaceInfo',
  props: {
    place: {
      type: Object as PropType<Place>,
      required: true,
    },
  },
  setup: (props) => {
    const mappedPlace = plainToClass(Place, props.place);
    return { mappedPlace };
  },
});
