export class Employee {
  public id = '';

  public surname = '';

  public name = '';

  public middleName?: string | null;

  public code: string | null = null

  public initials(): string {
    const middleNameInitials = this.middleName == null ? '' : `${this.middleName[0]}.`;
    return `${this.surname} ${this.name[0]}. ${middleNameInitials}`;
  }

  constructor(init?: Partial<Employee>) {
    Object.assign(this, init);
  }
}
