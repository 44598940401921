export abstract class TripTaskTypeKeys {
  static readonly AppearanceCallTask = 'AppearanceCallTask';

  static readonly AppearanceTask = 'AppearanceTask';

  static readonly MedicalExaminationTask = 'MedicalExaminationTask';

  static readonly FuelEquipmentTask = 'FuelEquipmentTask';

  static readonly FuelEquipmentStartTask = 'FuelEquipmentStartTask';

  static readonly FuelEquipmentEndTask = 'FuelEquipmentEndTask';

  static readonly WorkEndTask = 'WorkEndTask';

  static readonly StartCheckpoint = 'StartCheckpoint';

  static readonly StopCheckpoint = 'StopCheckpoint';

  static readonly RestStartTask = 'RestStartTask';

  static readonly RestTask = 'RestTask';

  static readonly RestEndTask = 'RestEndTask';

  static readonly LocomotiveReturnTask = 'LocomotiveReturnTask';

  static readonly AttachWagonsTask = 'AttachWagonsTask';

  static readonly DetachWagonsTask = 'DetachWagonsTask';

  static readonly LocomotiveAcceptanceTask = 'LocomotiveAcceptanceTask';

  static readonly LocomotiveAcceptanceStartTask = 'LocomotiveAcceptanceStartTask';

  static readonly LocomotiveAcceptanceEndTask = 'LocomotiveAcceptanceEndTask';

  static readonly LocomotiveReturnStartTask = 'LocomotiveReturnStartTask';

  static readonly LocomotiveReturnEndTask = 'LocomotiveReturnEndTask';

  static readonly LocomotiveAssignmentTask = 'LocomotiveAssignmentTask';

  static readonly StationDepartureTask = 'StationDepartureTask';

  static readonly StationArrivalTask = 'StationArrivalTask';

  static readonly StationFollowUpTask = 'StationFollowUpTask';

  static readonly PassengerTravelTask = 'PassengerTravelTask';

  static readonly PassengerTravelStartTask = 'PassengerTravelStartTask';

  static readonly PassengerTravelEndTask = 'PassengerTravelEndTask';

  static readonly InfrastructureJointTrackFollowUpTask = 'InfrastructureJointTrackFollowUpTask';
}
