import { Type } from 'class-transformer';

export class Summary {
  public workingHours = 0;

  @Type(() => Date)
  public payDate!: Date;

  public accruedMoney = 0;

  public preparatoryActionRateCompliance = '';

  public continuousWorkCompliance = '';

  public safety = '';

  public scheduleCompliance = '';

  public fuelConsumption = '';
}
