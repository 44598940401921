import { roundToNearestMinutes } from 'date-fns';

export class DateComparer {
  public static compareToMinutes(firstDate: Date, secondDate: Date) : boolean {
    const firstDateRounded = roundToNearestMinutes(firstDate, {
      nearestTo: 1,
    });
    const secondDateRounded = roundToNearestMinutes(secondDate, {
      nearestTo: 1,
    });

    return firstDateRounded === secondDateRounded;
  }

  public static firstDateIsGreater(firstDate: Date, secondDate: Date) : boolean {
    const firstDateRounded = roundToNearestMinutes(firstDate, {
      nearestTo: 1,
    });
    const secondDateRounded = roundToNearestMinutes(secondDate, {
      nearestTo: 1,
    });

    return firstDateRounded > secondDateRounded;
  }
}
