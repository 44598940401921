import { Type } from 'class-transformer';
import { ContinuousWork } from '@/models/trip/ContinuousWork';

export class TripVersion {
  public id = ''

  @Type(() => Date)
  public versionDate!: Date

  public number!: number

  @Type(() => ContinuousWork)
  public continuousWorks: ContinuousWork[] = []
}
