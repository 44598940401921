import { Type } from 'class-transformer';
import { Employee } from '@/models/trip/Employee';

export class EmployeeInCrew {
  @Type(() => Employee)
  public employee!: Employee

  public role = ''

  public index = 0
}
