import { Type } from 'class-transformer';
import { TripItemModel } from '@/models/trip/TripItemModel';
import { TripEventModelWithTask } from '@/models/trip/TripEventModelWithTask';
import { WorkTaskType } from '@/models/orders/WorkTaskType';
import { TripTaskTypeKeys } from '@/models/trip/TripTaskTypeKeys';

export class Route {
  public id = '';

  public number = '';

  public beginTaskId : string | null = null

  public endTaskId : string | null = null

  // @Type((ops) => {
  //   debugger;
  //   return TripItemModel;
  //   return ([ops?.object[ops?.property]] as any).type.code == 'TaskOrEvent'
  //   ? TripEventModelWithTask : TripItemModel;
  // })
  // https://github.com/typestack/class-transformer/issues/51
  @Type(() => TripEventModelWithTask)
  public routeEvents: Array<TripEventModelWithTask> = [];
  // public routeEvents: Array<TripItemModel | TripEventModelWithTask> = [];

  public firstEvent(versionId: string) : TripEventModelWithTask {
    return this.routeEvents
      .filter((re) => re.tripTask != null
        && re.tripTask.id === this.beginTaskId)[0];
  }

  public lastEvent(versionId: string) : TripEventModelWithTask {
    const tasks = this.routeEvents
      .filter((re) => re.tripTask != null
        && re.tripTask.id === this.endTaskId);
    return tasks[tasks.length - 1];
  }

  public get workOnLocomotiveExists() : boolean {
    for (let i = 0; i < this.routeEvents.length; i++) {
      const routeEvent = this.routeEvents[i];

      if (routeEvent.tripTask?.type.code === TripTaskTypeKeys.LocomotiveAcceptanceTask) {
        return true;
      }
    }

    return false;
  }

  public get workOnLocomotive() : TripEventModelWithTask | null {
    for (let i = 0; i < this.routeEvents.length; i++) {
      const routeEvent = this.routeEvents[i];

      if (routeEvent.tripTask.type.code === TripTaskTypeKeys.LocomotiveAcceptanceTask) {
        return routeEvent;
      }
    }

    return null;
  }
}
