import { TripTask } from '@/models/trip/TripTask';
import { Place } from '@/models/trip/concrete-trip-task/Place';
import { Type } from 'class-transformer';

export class StationPassage extends TripTask {
  @Type(() => Place)
  public currentPoint!: Place

  @Type(() => Place)
  public nextPoint: Place | null = null

  public trainNumber = ''
}
