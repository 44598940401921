var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"rounded-0 rd-card rd-simple-trip-task-card",attrs:{"elevation":"0"}},[_c('v-container',{key:_vm.tripEventWithTask.tripTask.id,attrs:{"fluid":""}},[_c('v-row',[_c('v-col',[_c('div',{staticClass:"text-start"},[(_vm.tripEventWithTask.tripEvent != null)?_c('div',{staticClass:"d-inline-flex flex-row",staticStyle:{"padding-inline-end":"5px"}},[(_vm.compareDates(_vm.tripEventWithTask.tripEvent.date, _vm.tripEventWithTask.tripTask.planningExecutionTime))?_c('div',{staticClass:"d-inline-flex flex-row",staticStyle:{"flex-direction":"row"}},[_vm._v(" "+_vm._s(_vm.toTime(_vm.tripEventWithTask.tripEvent.date))+" ")]):_c('div',{staticClass:"d-inline-flex flex-row"},[_c('div',{staticClass:"inactive"},[_vm._v(" "+_vm._s(_vm.toTime(_vm.tripEventWithTask.tripTask.planningExecutionTime))+" ")]),_c('div',{staticClass:"d-inline-flex flex-row"},[_vm._v(" / ")]),_c('div',{staticClass:"d-inline-flex flex-row",class:{
                  'up-on-time': _vm.firstDateIsGreater(_vm.tripEventWithTask.tripTask.planningExecutionTime, _vm.tripEventWithTask.tripEvent.date),
                  'expired': !_vm.firstDateIsGreater(_vm.tripEventWithTask.tripTask.planningExecutionTime, _vm.tripEventWithTask.tripEvent.date),
                }},[_vm._v(" "+_vm._s(_vm.toTime(_vm.tripEventWithTask.tripEvent.date))+" ")])])]):_c('div',{staticClass:"inactive",staticStyle:{"display":"inline-flex","flex-direction":"row","padding-inline-end":"5px"}},[_vm._v(" "+_vm._s(_vm.toTime(_vm.tripEventWithTask.tripTask.planningExecutionTime))+" ")]),_c('div',{staticClass:"d-inline-flex flex-row font-weight-bold text-truncate cut-type-name"},[_vm._v(" "+_vm._s(_vm.tripEventWithTask.tripTask.type.name)+" ")])])])],1),_c('v-row',[_c('v-col',[([
              _vm.TripTaskTypeKeys.StationDepartureTask,
              _vm.TripTaskTypeKeys.StationFollowUpTask,
              _vm.TripTaskTypeKeys.StationArrivalTask
              ].includes(_vm.tripEventWithTask.tripTask.type.code))?_c('station-passage-info',{attrs:{"station-passage":_vm.tripEventWithTask}}):(_vm.tripEventWithTask.tripTask.executed &&
                     _vm.tripEventWithTask.tripTask.type.code === _vm.TripTaskTypeKeys.MedicalExaminationTask)?_c('medical-info'):(_vm.tripEventWithTask.tripTask.type.code === _vm.TripTaskTypeKeys.InfrastructureJointTrackFollowUpTask)?_c('infrastructure-follow-up',{attrs:{"follow-up":_vm.tripEventWithTask}}):(_vm.tripEventWithTask.tripTask.place != null)?_c('place-info',{attrs:{"place":_vm.tripEventWithTask.tripTask.place}}):_vm._e()],1),_c('v-col',{attrs:{"cols":"2"}},[_c('v-container',{staticClass:"pa-0"},[_c('v-row',{staticClass:"pa-0"},[_c('v-col',[_c('div',{staticClass:"text-end"},[_c('v-icon',{staticStyle:{"cursor":"pointer"}},[_vm._v(" visibility ")])],1)])],1)],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }